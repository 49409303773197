import React, { useEffect, useState } from "react";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import "../../../assets/scss/Talent/Layout/MyResume/Modal/WorkHistoryModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import GlobalText from "../../../assets/global/GlobalText.json";
import axios from "axios";
import SuccessModal from "../../SuccessModal";
import WarningModal from "../../WarningModal";
import Loader from "../../../layouts/loader/Loader";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import Config from "../../../Config/config";

const EditWorkHistoryModal = ({
  isOpen,
  editHistory,
  close,
  reloadWorkHistory,
  setFindCompanyName,
  hasAI,
  // AIList,
}) => {
  // console.log(editHistory);
  const token = useAuthToken();
  const apiKey = process.env.REACT_APP_TALENT_API;

  const [companyName, setcompanyName] = useState(
    editHistory?.companyName || editHistory?.data?.companyName || ""
  );

  const checkDropdownValue = (value, options) =>
    options.find((option) => option.value === value) ? value : "";

  const [jobLevel, setjobLevel] = useState(
    checkDropdownValue(
      editHistory?.jobLevel || editHistory?.data?.jobLevel || "",
      GlobalText.jobLevelOption
    )
  );

  const [jobRole, setjobRole] = useState(
    editHistory?.jobRole || editHistory?.data?.jobRole || ""
  );

  const [employmentType, setemploymentType] = useState(
    checkDropdownValue(
      editHistory?.employmentType || editHistory?.data?.employmentType || "",
      GlobalText.employmentType
    )
  );

  const [workSetup, setworkSetup] = useState(
    checkDropdownValue(
      editHistory?.workSetup || editHistory?.data?.workSetup || "",
      GlobalText.workSetupOption
    )
  );

  const [industry, setindustry] = useState(
    checkDropdownValue(
      editHistory?.industry || editHistory?.data?.industry || "",
      GlobalText.industryOptions
    )
  );

  const [startDate, setstartDate] = useState(
    editHistory?.experienceDate?.startDate
      ? moment(editHistory?.experienceDate?.startDate).format("YYYY-MM-DD")
      : ""
  );

  const [endDate, setendDate] = useState(
    editHistory?.experienceDate?.endDate
      ? moment(editHistory?.experienceDate?.endDate).format("YYYY-MM-DD")
      : ""
  );

  const [isPresentClicked, setisPresentClicked] = useState(
    editHistory?.isPresent === 0 || editHistory?.data?.isPresent === 0
      ? true
      : false
  );

  const [isLoading, setIsLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleModal2 = () => {
    setModalOpen2(!modalOpen2);
  };

  const handleConfirm = () => {
    setModalOpen(!modalOpen);
    setFindCompanyName(editHistory?.companyName);
    reloadWorkHistory();

    // window.location.reload();
  };

  useEffect(() => {
    if (editHistory?.isPresent === 0 || editHistory?.data?.isPresent === 0) {
      setisPresentClicked(false);
    } else {
      setisPresentClicked(true);
    }
  }, []);

  const [errors, setErrors] = useState({
    companyName: "",
    jobRole: "",
    jobLevel: "",
    employmentType: "",
    workSetup: "",
    industry: "",
    startDate: "",
    endDate: "",
  });

  const handleValidation = () => {
    let newErrors = {};

    if (jobRole === "") {
      newErrors.jobRole = "Most Recent Job is required";
    }
    if (jobLevel === "") {
      newErrors.jobLevel = "Job Level is required";
    }
    if (companyName === "") {
      newErrors.companyName = "Most Recent Company is required";
    }

    if (companyName.length > 255) {
      newErrors.companyName = "Company name must be 255 characters or less";
    }

    if (employmentType === "") {
      newErrors.employmentType = "Employment Type is required";
    }
    if (workSetup === "") {
      newErrors.workSetup = "Work Setup is required";
    }
    if (industry === "") {
      newErrors.industry = "Industry is required";
    }
    if (startDate === "") {
      newErrors.startDate = "Start Date is required";
    }
    if (endDate === "") {
      newErrors.endDate = "End Date is required";
    }

    if (jobRole.length > 255) {
      newErrors.jobRole = "Job role must be 255 characters or less";
    }

    if (moment(endDate).isSameOrBefore(moment(startDate))) {
      newErrors.endDate = "End date must be greater than start date";
    }

    // Set the new errors
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  // console.log(editHistory);
  const SaveHistory = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true

    // Validation Function Triggering
    const isValid = handleValidation();

    if (!isValid) {
      setIsLoading(false);
      return;
    }
    const today = moment().format("YYYY-MM-DD");
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };

      let payload = {
        id: editHistory.id,
        companyName,
        jobLevel,
        jobRole,
        employmentType,
        workSetup,
        industry,
        experienceDate: {
          startDate,
          endDate: isPresentClicked ? today : endDate,
        },
        createdAt: editHistory.createdAt,
        isDeleted: editHistory.isDeleted,
        isPresent: isPresentClicked && editHistory.isPresent !== 0 ? 0 : 1,
      };

      let response;
      if (editHistory?.id) {
        payload = {
          ...payload,
          id: editHistory.id,
          createdAt: editHistory.createdAt,
          isDeleted: editHistory.isDeleted,
          isPresent: isPresentClicked && editHistory.isPresent !== 0 ? 0 : 1,
        };

        response = await axios.patch(
          `${Config.nxtApi}/api/talent/updateworkhistory`,
          payload,
          { headers }
        );
      } else {
        response = await axios.post(
          `${Config.nxtApi}/api/talent/workhistory`,
          payload,
          { headers }
        );
      }

      const { data } = response;

      if (data.code === "0") {
        toggleModal();
      } else {
        toggleModal2();
      }
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  const clickPresent = () => {
    setisPresentClicked(!isPresentClicked);
    if (!isPresentClicked) {
      const today = moment().format("YYYY-MM-DD");
      setendDate(today);
    }
  };
  const [AIList, setAIList] = useState([]);
  const saveAIHistory = async (e) => {
    e.preventDefault();
    // setIsLoading(true); // Set loading state to true

    // Validation Function Triggering
    const isValid = handleValidation();

    if (!isValid) {
      setIsLoading(false);
      return;
    }

    const newAIHistory = {
      id: editHistory.id,
      companyName,
      jobLevel,
      jobRole,
      employmentType,
      workSetup,
      industry,
      experienceDate: {
        startDate,
        endDate,
      },
      createdAt: editHistory.createdAt,
      isDeleted: editHistory.isDeleted,
      isPresent: isPresentClicked && editHistory.isPresent !== 0 ? 0 : 1,
    };

    setAIList((prevAIList) => {
      const updatedList = [...prevAIList, newAIHistory];
      console.log(updatedList); // Log the updated list to ensure it's correct
      return updatedList;
    });
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={close} className="modal-container">
        <ModalHeader
          toggle={() => {
            close();
          }}
          className="modal-header-container"
        >
          Work History
        </ModalHeader>
        <ModalBody className="modal-body-container">
          <FormGroup>
            <Label>
              Company Name <span className="required-field">*</span>
            </Label>
            <FormGroup className="logo-company-name">
              {/* <div className="logo-container" onClick={clickLogo}>
                <FontAwesomeIcon icon={faBuilding} className="icon" />
                <Input type="file" hidden id="upload-logo" />
              </div> */}
              <Input
                type="text"
                value={companyName}
                invalid={!!errors.companyName}
                onFocus={() => setErrors({ ...errors, companyName: "" })}
                onChange={(event) => {
                  setcompanyName(event.target.value);
                }}
              />
              {errors.companyName ? (
                <FormFeedback className="error-msg" color="danger">
                  {errors.companyName}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </FormGroup>
          <FormGroup>
            <Label>Job Level</Label>
            <Input
              type="select"
              value={jobLevel}
              invalid={!!errors.jobLevel}
              onFocus={() => setErrors({ ...errors, jobLevel: "" })}
              onChange={(event) => {
                setjobLevel(event.target.value);
              }}
            >
              {GlobalText.jobLevelOption.map((option, key) => (
                <option
                  key={key}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </option>
              ))}
            </Input>
            {errors.jobLevel ? (
              <FormFeedback className="error-msg" color="danger">
                {errors.jobLevel}
              </FormFeedback>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label>
              Role<span className="required-field">*</span>
            </Label>
            <Input
              type="text"
              value={jobRole}
              invalid={!!errors.jobRole}
              onFocus={() => setErrors({ ...errors, jobRole: "" })}
              onChange={(event) => {
                setjobRole(event.target.value);
              }}
            />
            {errors.jobRole ? (
              <FormFeedback className="error-msg" color="danger">
                {errors.jobRole}
              </FormFeedback>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label>Work Setup</Label>
            <Input
              type="select"
              value={employmentType}
              invalid={errors.employmentType}
              onFocus={() => setErrors({ ...errors, employmentType: "" })}
              onChange={(event) => {
                setemploymentType(event.target.value);
              }}
            >
              {GlobalText.employmentType.map((option, key) => (
                <option
                  key={key}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </option>
              ))}
            </Input>
            {errors.employmentType ? (
              <FormFeedback className="error-msg" color="danger">
                {errors.employmentType}
              </FormFeedback>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label>Work Arrangement</Label>
            <Input
              type="select"
              onChange={(event) => {
                setworkSetup(event.target.value);
              }}
              value={workSetup}
              invalid={!!errors.workSetup}
              onFocus={() => setErrors({ ...errors, workSetup: "" })}
            >
              {GlobalText.workSetupOption.map((option, key) => (
                <option
                  key={key}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </option>
              ))}
            </Input>
            {errors.workSetup ? (
              <FormFeedback className="error-msg" color="danger">
                {errors.workSetup}
              </FormFeedback>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label>Industry</Label>
            <Input
              type="select"
              value={industry}
              invalid={!!errors.industry}
              onFocus={() => setErrors({ ...errors, industry: "" })}
              onChange={(event) => {
                setindustry(event.target.value);
              }}
            >
              <option disabled value="">
                Select an Industry
              </option>
              {GlobalText.industryOptions.map((option, key) => (
                <option
                  key={key}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </option>
              ))}
            </Input>
            {errors.industry ? (
              <FormFeedback className="error-msg" color="danger">
                {errors.industry}
              </FormFeedback>
            ) : null}
          </FormGroup>
          <div>
            <div className="start-end-date">
              <FormGroup>
                <Label>
                  Start Date<span className="required-field">*</span>
                </Label>
                <Input
                  type="date"
                  value={startDate}
                  invalid={!!errors.startDate}
                  onFocus={() => setErrors({ ...errors, startDate: "" })}
                  onChange={(event) => {
                    setstartDate(event.target.value);
                  }}
                ></Input>
                {errors.startDate ? (
                  <FormFeedback
                    className="error-msg"
                    color="danger"
                    // style={{ display: "block" }}
                  >
                    {errors.startDate}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <FormGroup>
                <div className="date-checkbox">
                  {isPresentClicked ? (
                    <FormGroup></FormGroup>
                  ) : (
                    <>
                      <Label>
                        End Date<span className="required-field">*</span>
                      </Label>
                      <Input
                        type="date"
                        value={endDate}
                        onChange={(event) => {
                          setendDate(event.target.value);
                        }}
                        // disabled={isPresentClicked}
                      ></Input>
                    </>
                  )}

                  <div
                    className="checkbox"
                    id="checkboxContainer"
                    style={{ margin: isPresentClicked ? "40px 0" : "0" }}
                  >
                    <Input
                      type="checkbox"
                      onClick={clickPresent}
                      checked={isPresentClicked}
                    />{" "}
                    <Label>Present</Label>
                  </div>
                </div>
              </FormGroup>
            </div>
            <FormGroup className="start-end-msg"></FormGroup>
          </div>
        </ModalBody>
        <ModalFooter className="modal-footer-container">
          <Button
            className="cancel"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </Button>
          <Button className="save" onClick={SaveHistory}>
            {isLoading ? <Loader /> : "Save"}
          </Button>
        </ModalFooter>
      </Modal>
      <SuccessModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        iconImage={require("../../../assets/images/SuccessIcon.png")}
        modalLabel="Success"
        modalSublabel="Successfully edited work history."
        confirmButtonText="Confirm"
        onConfirm={handleConfirm}
      />
      <WarningModal
        modalOpen={modalOpen2}
        toggleModal={toggleModal2}
        iconImage={require("../../../assets/images/WarningIcon.png")}
        modalLabel="Warning"
        modalSublabel="Internal Server Error"
        confirmButtonText="Confirm"
        onConfirm={toggleModal2}
      />
    </div>
  );
};

export default EditWorkHistoryModal;
