import React, { useEffect, useState } from "react";
import { Alert, Button, Label } from "reactstrap";
import "../../../assets/scss/Talent/Layout/MyResume/WorkHistory.scss";
import WorkHistoryModal from "../../FormTalent/MyResume/WorkHistoryModal";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import QuestionModal from "../../QuestionModal";
import Loader from "../../../layouts/loader/Loader";
import EditWorkHistoryModal from "../../FormTalent/MyResume/EditWorkHistoryModal";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import Config from "../../../Config/config";
import { RequestMethod } from "../../../Utilities/Reusable/Method/RequestMethod";
import CustomModal from "../../UI/Modal";
import { Message } from "../../../Utilities/Reusable/Message/Message";
import { useTalent } from "../../../hook/useTalent";
import GlobalText from "../../../assets/global/GlobalText.json";

const WorkHistory = ({ hasAI, workHistory }) => {
  const { addAllWorkHistories } = useTalent();
  const lengthHasAi = hasAI?.workHistory?.length;
  console.log(lengthHasAi);
  console.log(lengthHasAi);
  console.log(hasAI, workHistory);
  const initialMergedWorkHistory = [
    ...(hasAI?.workHistory || []),
    ...(workHistory.data?.data || []),
  ];
  console.log(initialMergedWorkHistory);
  //
  const [mergedWorkHistory, setMergedWorkHistory] = useState(
    initialMergedWorkHistory.map((item) => ({
      ...item,
      companyName:
        item.companyName.length > 255
          ? `${item.companyName.slice(0, 255)}`
          : item.companyName,
      jobRole:
        item.jobRole.length > 255
          ? `${item.jobRole.slice(0, 255)}`
          : item.jobRole,
    }))
  );

  console.log(mergedWorkHistory);

  const token = useAuthToken();
  const apiKey = process.env.REACT_APP_TALENT_API;

  const [workHistoryFreshGrad, setworkHistoryFreshGrad] = useState([]);
  const [employeeType, setEmployeeType] = useState("");
  const [showWorkHistoryModal, setShowWorkHistoryModal] = useState(false);
  const [selectedWork, setSelectedWork] = useState(null);
  const [editWorkHistoryModal, setEditWorkHistoryModal] = useState(false);
  const [warningModalOpen, setwarningModalOpen] = useState(false);
  const [successModalOpen, setsuccessModalOpen] = useState(false);
  const [selectedWorkHistory, setSelectedWorkHistory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({ show: false });
  const [showActionButtons, setShowActionButtons] = useState(
    hasAI?.workHistory
  );
  const [shouldReload, setShouldReload] = useState(false);

  const toggleSuccess = () => {
    setsuccessModalOpen(!successModalOpen);
  };

  const clickWorkHistoryBtn = () => {
    setShowWorkHistoryModal(true);
  };

  const clickEditWorkHistory = (data) => {
    const truncatedData = {
      ...data,
      companyName:
        data.companyName.length > 255
          ? `${data.companyName.slice(0, 255)}`
          : data.companyName,
      jobRole:
        data.jobRole.length > 255
          ? `${data.jobRole.slice(0, 255)}`
          : data.jobRole,
    };

    setSelectedWork(truncatedData);
    setEditWorkHistoryModal(true);
  };

  const deleteCert = (
    id,
    companyName,
    jobLevel,
    jobRole,
    employmentType,
    workSetup,
    industry,
    startDate,
    endDate,
    createdAt,
    isPresent
  ) => {
    setSelectedWorkHistory({
      id,
      companyName,
      jobLevel,
      jobRole,
      employmentType,
      workSetup,
      industry,
      startDate,
      endDate,
      createdAt,
      isPresent,
    });
  };

  const confirmDelete = async () => {
    setIsLoading(true);
    try {
      const payload = {
        id: selectedWorkHistory.id,
        companyName: selectedWorkHistory.companyName,
        jobLevel: selectedWorkHistory.jobLevel,
        jobRole: selectedWorkHistory.jobRole,
        employmentType: selectedWorkHistory.employmentType,
        workSetup: selectedWorkHistory.workSetup,
        industry: selectedWorkHistory.industry,
        experienceDate: {
          startDate: selectedWorkHistory.startDate,
          endDate: selectedWorkHistory.endDate,
        },
        createdAt: selectedWorkHistory.createdAt,
        isDeleted: 1,
        isPresent: selectedWorkHistory.isPresent,
      };

      await axios
        .patch(`${Config.nxtApi}/api/talent/updateworkhistory`, payload, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          setMergedWorkHistory((prev) =>
            prev.filter((item) => item.id !== selectedWorkHistory.id)
          );

          setsuccessModalOpen(false);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("Error ", err);
        });
    } catch (error) {
      console.error("Error ", error);
      setIsLoading(false);
    }
  };

  const getWorkHistory = async () => {
    try {
      const payload = {
        numRow: 10,
        sortKeys: "desc",
      };

      const response = await axios.post(
        `${Config.nxtApi}/api/talent/workhistories`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const validHasAIWorkHistory = (hasAI?.workHistory || []).filter(
        (aiItem) =>
          !response.data.data.some(
            (responseItem) =>
              responseItem.companyName === aiItem.companyName &&
              responseItem.jobRole === aiItem.jobRole &&
              !aiItem.id
          )
      );

      console.log(
        "[[[[[[[[[[[[[[[[first]]]]]]]]]]]]]]]]",
        validHasAIWorkHistory
      );

      const updatedWorkHistory = [
        ...response.data.data,
        ...validHasAIWorkHistory,
      ];

      // Sorting updatedWorkHistory: entries from hasAI will be placed at the end
      updatedWorkHistory.sort((a, b) => {
        const isAFromAI = hasAI.workHistory.includes(a);
        const isBFromAI = hasAI.workHistory.includes(b);
        return isAFromAI - isBFromAI;
      });

      console.log(updatedWorkHistory);

      // Setting states with updated work history data
      setworkHistoryFreshGrad(response.data);
      setMergedWorkHistory(updatedWorkHistory);
      setEmployeeType(response.data.employeeType);
      deteleCompanyName();
    } catch (err) {
      console.error("Error fetching work history", err);
    }
  };

  // useEffect(() => {
  //   console.log(lengthHasAi, mergedWorkHistory);

  //   setShowActionButtons(
  //     mergedWorkHistory.filter((item) => !item.applicantId).length > 1 ||
  //       lengthHasAi > 0
  //   );
  // }, [hasAI, lengthHasAi, mergedWorkHistory]);

  useEffect(() => {
    if (workHistory || hasAI) {
      const combinedWorkHistory = [
        ...(hasAI?.workHistory || []),
        ...(workHistory.data?.data || []),
      ];
      setworkHistoryFreshGrad(workHistory.data);
      setMergedWorkHistory(
        combinedWorkHistory.map((item) => ({
          ...item,
          companyName:
            item.companyName.length > 255
              ? `${item.companyName.slice(0, 255)}`
              : item.companyName,
          jobRole:
            item.jobRole.length > 255
              ? `${item.jobRole.slice(0, 255)}`
              : item.jobRole,
        }))
      );
      setEmployeeType(workHistory.data.employeeType);
    }
  }, [workHistory, hasAI]);

  const [aiList, setAIList] = useState(null);
  console.log(aiList);
  const handleSaveResult = () => {
    let hasError = false;
    const noApplicantId = mergedWorkHistory.filter((item) => !item.applicantId);

    mergedWorkHistory.forEach((work) => {
      const {
        companyName,
        jobLevel,
        jobRole,
        employmentType,
        workSetup,
        industry,
        experienceDate,
        isPresent,
      } = work;

      const emptyProperties = [];

      if (!companyName) emptyProperties.push("companyName");
      if (!jobLevel) emptyProperties.push("jobLevel");
      if (!jobRole) emptyProperties.push("jobRole");
      if (!employmentType) emptyProperties.push("employmentType");
      if (!workSetup) emptyProperties.push("workSetup");
      if (!industry) emptyProperties.push("industry");
      if (!experienceDate?.startDate) emptyProperties.push("startDate");
      if (!experienceDate?.endDate) emptyProperties.push("endDate");
      if (isPresent === undefined) emptyProperties.push("isPresent");

      if (emptyProperties.length > 0) {
        setModalInfo({
          show: true,
          type: "Error",
          message: Message.ai.cvParsing[400],
          bName: "OK",
        });
        console.error(
          "Incomplete Work History. Please fill in all required fields for your work history details before saving.",
          companyName
        );
      } else {
        const dropdownOptions = [
          "jobLevelOption",
          "employmentType",
          "workSetupOption",
          "industryOptions",
        ];

        console.log(dropdownOptions);
        dropdownOptions.forEach((field) => {
          const fieldName = field.replace(/Option(s)?$/, "");
          console.log(fieldName);
          const fieldValue = mergedWorkHistory[0][fieldName];
          if (
            !GlobalText[field] ||
            !GlobalText[field].find((option) => option.value === fieldValue)
          ) {
            console.error(
              `Missing or invalid ${
                fieldName === "industry" ? "industry" : fieldName
              } in GlobalText.`
            );
            setModalInfo({
              show: true,
              type: "Error",
              message: Message.ai.cvParsing[401],
              bName: "OK",
            });
            hasError = true;
          }
        });
      }
    });

    if (!hasError) {
      console.log(noApplicantId);
      addAllWorkHistories(noApplicantId);
    }
  };

  const handleCancelResult = () => {
    setModalInfo({
      show: true,
      type: "Warning",
      message: Message.ai.cvParsing[100],
      func: "cancelResult",
      bName: "Yes",
    });
  };

  const cancelResult = () => {
    if (showActionButtons) {
      const aiWorkHistoryIds = new Set(
        hasAI.workHistory.map((item) => item.id)
      );
      setMergedWorkHistory((prev) =>
        prev.filter((item) => !aiWorkHistoryIds.has(item.id))
      );

      // setShowActionButtons(
      //   mergedWorkHistory.filter((item) => !item.applicantId).length > 1
      // );

      setShowActionButtons(false);
      setModalInfo({});
    }
  };

  const [deleteAIData, setDeleteAIData] = useState(null);
  const showDeleteAIWH = (data) => {
    setDeleteAIData(data);
    setModalInfo({
      show: true,
      type: "Warning",
      message: Message.ai.cvParsing[101],
      func: "deleteAIWH",
      bName: "OK",
    });
  };

  const deleteAIWH = () => {
    console.log("dddddddddddddddddddd", mergedWorkHistory, deleteAIData);
    setMergedWorkHistory((prev) => {
      const filteredWorkHistory = prev
        .filter(
          (item) =>
            !(
              item.companyName === deleteAIData.companyName && !item.applicantId
            )
        )
        .sort((a, b) => {
          if (!a.applicantId && b.applicantId) return -1;
          if (a.applicantId && !b.applicantId) return 1;
          return 0;
        });

      return filteredWorkHistory;
    });

    // const countNoApplicantId = mergedWorkHistory.filter(
    //   (item) => !item.applicantId
    // ).length;
    setShowActionButtons(
      mergedWorkHistory.filter((item) => !item.applicantId).length > 1 ||
        lengthHasAi > 0
    );
    setModalInfo({});
  };

  const [findCompanyName, setFindCompanyName] = useState(null);
  const handleReloadWorkHistory = () => {
    setShowWorkHistoryModal(false);
    setEditWorkHistoryModal(false);
    getWorkHistory();
  };

  const deteleCompanyName = () => {
    console.log(findCompanyName);
    if (findCompanyName) {
      console.log("Im in", findCompanyName);
      const updatedWorkHistory = mergedWorkHistory.filter(
        (entry) => entry.companyName !== findCompanyName || entry.applicantId
      );

      setMergedWorkHistory(updatedWorkHistory);
      console.log(updatedWorkHistory, mergedWorkHistory);
    }
    setShowActionButtons(
      mergedWorkHistory.filter((item) => !item.applicantId).length !== 1
    );
  };

  console.log(showWorkHistoryModal);

  useEffect(() => {
    const filteredWorkHistory = mergedWorkHistory.filter(
      (item) => !item.applicantId && item.companyName
    );

    setShowActionButtons(filteredWorkHistory.length > 0);

    console.log(mergedWorkHistory);
  }, [mergedWorkHistory]);

  return (
    <div>
      {employeeType === "Fresh Graduate / Intern" ? (
        <>
          <div className="work-history-talent">
            <div className="header-container">
              <h6>Educational Information</h6>
            </div>
            <hr className="line-separator" />
            <div className="work-history-list-container">
              <div className="work-history-list">
                <Label>Educational Background</Label>
                <p>
                  - {workHistoryFreshGrad.studentInfo.educationalBackground}
                </p>
                <Label>Course</Label>
                <p>- {workHistoryFreshGrad.studentInfo.course}</p>
                <Label>School</Label>
                <p>- {workHistoryFreshGrad.studentInfo.school}</p>
                <Label>School Year</Label>
                <p>- {workHistoryFreshGrad.studentInfo.schoolYear}</p>
              </div>
            </div>
          </div>
          <div className="work-history-talent">
            <div className="header-container">
              <h6>WORK HISTORY</h6>
              <Button
                onClick={() => {
                  clickWorkHistoryBtn();
                }}
              >
                Add Work History
              </Button>
            </div>
            <hr className="line-separator" />
            <div className="work-history-list-container">
              {mergedWorkHistory.map((data) => (
                <div className="work-history-list" key={data.id}>
                  sdfsd
                  <Label>
                    {data.companyName} - {data.jobRole}
                  </Label>
                  <p>Job Level: {data.jobLevel}</p>
                  <p>
                    Time Period:{" "}
                    {moment(
                      data.experienceDate.startDate || data.startDate
                    ).format("MM/DD/YYYY")}{" "}
                    -
                    {data.isPresent === 0 ? (
                      "Present"
                    ) : (
                      <>
                        {moment(data.experienceDate.endDate).format(
                          "MM/DD/YYYY"
                        )}
                      </>
                    )}
                    {data.isPresent}
                  </p>
                  <div
                    className="remove-icon-container"
                    onClick={() => {
                      setsuccessModalOpen(true);
                      deleteCert(
                        data.id,
                        data.companyName,
                        data.jobLevel,
                        data.jobRole,
                        data.employmentType,
                        data.workSetup,
                        data.industry,
                        moment(data.experienceDate.startDate).format(
                          "MM-DD-YYYY"
                        ),
                        moment(data.experienceDate.endDate).format(
                          "MM-DD-YYYY"
                        ),
                        data.createdAt,
                        data.isPresent
                      );
                    }}
                  >
                    <div className="remove-work-container">
                      <FontAwesomeIcon icon={faTrash} className="remove-work" />{" "}
                      <span>Remove</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : employeeType === "Professional" ? (
        <div className="work-history-talent">
          <div className="header-container">
            <h6>WORK HISTORY</h6>
            <Button
              onClick={() => {
                clickWorkHistoryBtn();
              }}
            >
              Add Work History
            </Button>
          </div>
          <hr className="line-separator" />
          <div className="work-history-list-container">
            {mergedWorkHistory.map((data) => (
              <div className="work-history-list" key={data.id}>
                <Label>
                  {data.companyName} - {data.jobRole}
                </Label>
                <p>Job Level: {data.jobLevel}</p>
                <p>
                  Time Period:{" "}
                  {moment(
                    data.experienceDate.startDate || data.startDate
                  ).format("MM/DD/YYYY")}{" "}
                  -{" "}
                  {data.isPresent === 0 ? (
                    "Present"
                  ) : (
                    <>
                      {moment(
                        data.experienceDate.endDate || data.endDate
                      ).format("MM/DD/YYYY")}
                    </>
                  )}
                  asd {data.isPresent}
                </p>
                <div
                  className="remove-icon-container"
                  onClick={() => {
                    setsuccessModalOpen(true);
                    deleteCert(
                      data.id,
                      data.companyName,
                      data.jobLevel,
                      data.jobRole,
                      data.employmentType,
                      data.workSetup,
                      data.industry,
                      moment(
                        data.experienceDate.startDate || data.startDate
                      ).format("MM-DD-YYYY"),
                      moment(
                        data.experienceDate.endDate || data.endDate
                      ).format("MM-DD-YYYY"),
                      data.createdAt,
                      data.isPresent
                    );
                  }}
                >
                  <div className="remove-work-container">
                    <FontAwesomeIcon icon={faTrash} className="remove-work" />{" "}
                    <span>Remove</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="work-history-talent">
          <div className="header-container">
            <h6>Work History</h6>
            <Button
              onClick={() => {
                clickWorkHistoryBtn();
              }}
            >
              Add Work History
            </Button>
          </div>
          <hr className="line-separator" />{" "}
          <div className="work-history-list-container">
            {mergedWorkHistory?.length === 0 ? (
              <>
                <Alert color="secondary">
                  No Work History has been added yet
                </Alert>
              </>
            ) : (
              <>
                {mergedWorkHistory.map((data) => (
                  <div className="work-history-list" key={data.id}>
                    <Label>
                      {data.companyName} - {data.jobRole}
                    </Label>
                    <p>Job Level: {data.jobLevel}</p>
                    {/* <p>
                      Time Period:{" "}
                      {moment(
                        data.startDate || data.experienceDate.startDate
                      ).format("MM/DD/YYYY")}{" "}
                      -{" "}
                      {data.isPresent === 0 ? (
                        "Present"
                      ) : (
                        <>
                          {moment(
                            data.experienceDate.endDate || data.endDate
                          ).format("MM/DD/YYYY")}
                        </>
                      )}
                    </p> */}
                    <div className="remove-icon-container">
                      <div className="remove-work-container">
                        <div
                          onClick={() => {
                            if (
                              !hasAI?.workHistory.some(
                                (aiData) => aiData.id === data.id
                              )
                            ) {
                              setsuccessModalOpen(true);
                              deleteCert(
                                data.id,
                                data.companyName,
                                data.jobLevel,
                                data.jobRole,
                                data.employmentType,
                                data.workSetup,
                                data.industry,
                                moment(
                                  data.experienceDate.startDate ||
                                    data.startDate
                                ).format("MM-DD-YYYY"),
                                moment(
                                  data.experienceDate.endDate || data.endDate
                                ).format("MM-DD-YYYY"),
                                data.createdAt,
                                data.isPresent
                              );
                            } else {
                              showDeleteAIWH(data);
                              // setMergedWorkHistory((prev) =>
                              //   prev.filter(
                              //     (item, index) =>
                              //       item.companyName !== data.companyName
                              //   )
                              // );

                              // const countNoApplicantId =
                              //   mergedWorkHistory.filter(
                              //     (item) => !item.applicantId
                              //   ).length;
                              // console.log("========", countNoApplicantId);

                              // setShowActionButtons(countNoApplicantId !== 1);
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="remove-work"
                          />
                          <span>Remove</span>
                        </div>

                        <div>
                          <Button
                            className="view-attachment-btn"
                            onClick={() => {
                              clickEditWorkHistory(data);
                            }}
                          >
                            <FontAwesomeIcon />
                            Edit Work History
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          {showActionButtons && (
            <>
              <div className="d-flex mt-3">
                <Button
                  className="btn btn-workhistory_cancel ms-auto me-2"
                  onClick={handleCancelResult}
                >
                  Cancel Result
                </Button>
                <Button
                  className="btn btn-workhistory_save"
                  onClick={handleSaveResult}
                >
                  Save Result
                </Button>
              </div>
            </>
          )}
        </div>
      )}
      {showWorkHistoryModal}
      {showWorkHistoryModal && (
        <WorkHistoryModal
          reloadWorkHistory={handleReloadWorkHistory}
          isOpen={showWorkHistoryModal}
          close={() => setShowWorkHistoryModal(false)}
        />
      )}

      {editWorkHistoryModal && (
        <EditWorkHistoryModal
          isOpen={editWorkHistoryModal}
          editHistory={selectedWork}
          close={() => setEditWorkHistoryModal(false)}
          reloadWorkHistory={handleReloadWorkHistory}
          setFindCompanyName={setFindCompanyName}
          hasAI={lengthHasAi ? true : false}
          AIList={setAIList}
        />
      )}
      <QuestionModal
        modalOpen={successModalOpen}
        toggleModal={toggleSuccess}
        iconImage={require("../../../assets/images/QuestionIcon.png")}
        modalLabel="Warning!"
        modalSublabel="Are you sure you want to delete this work history?"
        confirmButtonText={isLoading ? <Loader /> : "Delete"}
        onConfirm={confirmDelete}
        onCancel={toggleSuccess}
      />

      <CustomModal
        show={modalInfo.show}
        modalType={modalInfo.type}
        modalMessage={modalInfo.message}
        buttonName={modalInfo.bName}
        hasOption={
          modalInfo.func === "cancelResult" || modalInfo.func === "deleteAIWH"
        }
        onHide={() => {
          setModalInfo({ ...modalInfo, show: false });
        }}
        onClick={() => {
          if (modalInfo.func === "cancelResult") {
            cancelResult();
          } else if (modalInfo.func === "deleteAIWH") {
            deleteAIWH();
          } else {
            setModalInfo({});
          }
        }}
      />
    </div>
  );
};

export default WorkHistory;
